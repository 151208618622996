import { IRegisteredState } from '@/lib/context/appServerState/types';
import { MiddlewareSettingsKeysEnum } from '@/utils/middleware/applySettings';

export type TCatalogModeState = typeof defaultState;

const defaultState = {
    isCatalogMode: false,
};

const setter = (state: TCatalogModeState, settingsValue: number) => {
    if (settingsValue === 1) {
        state.isCatalogMode = true;
    }
};

const serverState: IRegisteredState<TCatalogModeState> = {
    defaultState,
    id: MiddlewareSettingsKeysEnum.CATALOG_MODE,
    setter,
};

export default serverState;
