import { IRegisteredState } from '@/lib/context/appServerState/types';
import { MiddlewareSettingsKeysEnum } from '@/utils/middleware/applySettings';

export type TRootHomepageState = typeof defaultState;

const defaultState = {
    isRootHomepage: false,
};

const setter = (state: TRootHomepageState, settingsValue: number) => {
    if (settingsValue === 1) {
        state.isRootHomepage = true;
    }
};

const serverState: IRegisteredState<TRootHomepageState> = {
    defaultState,
    id: MiddlewareSettingsKeysEnum.ROOT_HOMEPAGE,
    setter,
};

export default serverState;
